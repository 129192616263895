<template>
	<div>
		<pui-datatable v-if="!masterDetail" :modelName="modelName" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:readOnly="readOnly"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'attributegrid',
	components: {},
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'attribute',
			modelColumnDefs: {
				disabled: {
					render: (data, type, row) => {
						return row.disabled == 0 ? `<i class="fas fa-check state-check"></i>` : '';
					}
				}
			}
		};
	},
	created() {
		this.$puiEvents.$on(`onPuiGotoTableDetail-${this.parentModelName}`, () => {
			// Load parent data for get on form
			if (this.masterDetail) {
				var thisModel = this.$store.getters.getModelByName(this.modelName);
				if (thisModel) {
					thisModel.storedData = {};
					thisModel.storedData.parentModelName = this.parentModelName;
				}
			}
		});
	},
	destroyed() {
		this.$puiEvents.$off(`onPuiGotoTableDetail-${this.parentModelName}`);
	}
};
</script>
<style lang="css">
.state-check {
	color: #00bcd4;
	font-size: 1em;
}
</style>
